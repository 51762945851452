<template>
  <div class="dashboard">
    <b-alert
      v-if="status === 1"
      class="success-alert text-color"
      dismissible
      show
    >
      <div
        class="text-dark p-4 d-inline-flex icon-bg mr-4"
        style="border-radius: 50%"
      >
        <b-icon
          icon="check-lg"
          aria-hidden="true"
          class="m-0 text-white"
        ></b-icon>
      </div>
      Başvurunuz Onaylandı!
    </b-alert>
    <b-alert v-else-if="status === 2" class="info-alert text-color" show>
      <div class="p-4 d-inline-flex icon-bg mr-4" style="border-radius: 50%">
        <b-icon icon="exclamation-lg" aria-hidden="true" class="m-0"></b-icon>
      </div>
      Başvurunuz Beklemede!
    </b-alert>

    <b-alert v-else class="danger-alert text-color d-flex align-items-center" show >
      <div class="p-4 d-inline-flex icon-bg mr-4" style="border-radius: 50%">
        <b-icon icon="x-lg" aria-hidden="true" class="m-0"></b-icon>
      </div>
      <div class="d-flex justify-content-start flex-column w-100" >
        <div class="d-flex justify-content-between">
          <span  @click="redGoster()" style="cursor: pointer;">Başvurunuz Red!</span>
        <b-icon 
        icon=" caret-down-fill" 
        aria-hidden="true" 
        v-b-tooltip.hover title="Onaylanmama sebebi için tıklayınız." 
        style="cursor: pointer;" 
        class="ml-4" 
        @click="redGoster()">
      </b-icon>
        </div>
        <p style="cursor: pointer;" @click="redGoster()" v-if="goster">Lorem, ipsum dolor.</p>
      </div>
    </b-alert>
    <InfoCards v-if="status === 1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      status: 1,
      goster: false,
    };
  },
  name: "dashboard",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      // { title: 'Ana Sayfa', route: '/' },
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  components: {
    InfoCards: () => import("@/components/dashboard/info-cards"),
  },
  methods: {
    redGoster: function(){
      this.goster = this.goster ===  true ? false : true
    }
  },
};
</script>
<style lang="scss">
.alert {
  padding: 19px !important;
  border-radius: 5px !important;
}
.dashboard {
  display: flex;
  flex-direction: column;
  gap: 2.14rem;
}
.success-alert {
  background-color: rgba(167, 229, 194, 0.499) !important;
  &.text-color {
    color: rgb(74, 74, 74) !important;
    font-weight: 500 !important;
    font-size: 14px;
  }
  .icon-bg {
    background-color: rgba(81, 220, 109, 0.738);
  }
  .close {
    font-size: 25px;
    top: 50% !important;
    transform: translateY(-50%) !important;
    color: rgb(74, 74, 74) !important;
  }
}
.info-alert {
  background-color: rgba(49, 135, 234, 0.788) !important;
  &.text-color {
    font-weight: 500 !important;
    font-size: 14px;
  }
  .icon-bg {
    background-color: rgb(255, 255, 255);
    color: rgba(49, 135, 234, 0.788) !important;
  }
}
.danger-alert {
  background-color: rgba(252, 87, 87, 0.705) !important;
  &.text-color {
    color: rgb(255, 255, 255) !important;
    font-weight: 500 !important;
    font-size: 14px;
  }
  .icon-bg {
    background-color: rgba(223, 45, 45, 0.844);
    color: rgb(255, 255, 255) !important;
    font-weight: 600 !important;
  }
}
</style>
